import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import md5 from 'js-md5';

import axios from 'axios'
import VueAxios from 'vue-axios'

let _ = require('lodash');

// axios.defaults.baseURL='http://127.0.0.1:8080';
axios.defaults.baseURL='.';


Vue.prototype.baseURL = axios.defaults.baseURL;
Vue.prototype.$md5 = md5;
Vue.prototype.$axios = axios

let waitCallbacks = [];

Vue.prototype.isReady = false;
Vue.prototype.$ready = function(callback){

  if(!callback){
    Vue.prototype.isReady = true;
  }

  if(Vue.prototype.isReady){
    callback && callback();

    waitCallbacks.forEach(c=>{
      c && c();
    })
    waitCallbacks = [];

  }else{
    callback && waitCallbacks.push(callback);
  }
}

Vue.prototype.$_ = _

Vue.use(VueAxios, axios)

Vue.use(Antd);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
